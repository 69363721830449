* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

a {
    text-decoration: none;
    color: rgb(61, 146, 201);
}
a:hover,
a:focus {
    text-decoration: underline;
}

h3 {
    font-weight: 100;
}

/* LAYOUT CSS */
.pure-img-responsive {
    max-width: 100%;
    height: auto;
}

#layout {
    padding: 0;
}

.header {
    text-align: center;
    top: auto;
    margin: 3em auto;
}

.sidebar {
    background: rgb(61, 79, 93);
    color: #fff;
}

.brand-title,
.brand-tagline {
    margin: 0;
}
.brand-title {
    text-transform: uppercase;
}
.brand-tagline {
    font-weight: 300;
    color: rgb(176, 202, 219);
    text-transform: uppercase;
}

.nav-list {
    margin: 0;
    padding: 0;
    list-style: none;
}
.nav-item {
    display: inline-block;
    *display: inline;
    zoom: 1;
}
.nav-item a {
    background: transparent;
    border: 2px solid rgb(176, 202, 219);
    color: #fff;
    margin-top: 1em;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-size: 85%;
}
.nav-item a:hover,
.nav-item a:focus {
    border: 2px solid rgb(61, 146, 201);
    text-decoration: none;
}

.content-subhead {
    text-transform: uppercase;
    color: #aaa;
    padding: 0.4em 0;
    font-size: 80%;
    font-weight: 500;
    letter-spacing: 0.1em;
}

.content {
    padding: 2em 1em 0;
}

.post {
    padding-bottom: 2em;
}
.post-title {
    font-size: 2em;
    color: #222;
    margin-bottom: 0.2em;
}
.post-avatar {
    border-radius: 50px;
    float: right;
    margin-left: 1em;
}
.post-description {
    color: #444;
    line-height: 1.8em;
}
.post-meta {
    color: #999;
    font-size: 90%;
    margin: 0;
    text-align: right;
    padding-top: 10px;
    border-top: 1px solid #eee;
}

.post-category {
    margin: 0 0.1em;
    padding: 0.3em 1em;
    color: #fff;
    background: #999;
    font-size: 80%;
}
    .post-category-design {
        background: #5aba59;
    }
    .post-category-pure {
        background: #4d85d1;
    }
    .post-category-yui {
        background: #8156a7;
    }
    .post-category-js {
        background: #df2d4f;
    }

.post-images {
    margin: 1em 0;
}
.post-image-meta {
    margin-top: -3.5em;
    margin-left: 1em;
    color: #fff;
    text-shadow: 0 1px 1px #333;
}

.footer {
    padding: 1em 0;
}
.footer a {
    color: #ccc;
    font-size: 80%;
}
.footer .pure-menu a:hover,
.footer .pure-menu a:focus {
    background: none;
}
h1 a {
    text-decoration: none;
    color: inherit;
}
h1 a:hover {
    text-decoration: none;
    color: rgb(176, 202, 219);
}

@media (min-width: 48em) {
    .content {
        padding: 2em 3em 0;
        margin-left: 25%;
    }

    .header {
        margin: 80% 2em 0;
        text-align: right;
    }

    .sidebar {
        position: fixed;
        top: 0;
        bottom: 0;
    }

    .footer {
        text-align: center;
    }
}
